import React from "react"
import { graphql, Link } from 'gatsby'
import { Parallax } from "react-parallax";
import Img from "gatsby-image"

import TEDxKIContainer from '../components/tedxki-container'
import DateTimeLocation from '../components/date-time-location'
import ButtonRed from '../components/button-red'
import ButtonWhite from '../components/button-white'
import TicketsBtn from '../components/tickets-btn'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import * as styles from './index.module.css'
import image1 from "../../static/images/unwritten.png"
import image2 from "../../static/images/bckgrnd.svg"

export default function HomePage({ data }) {
  const upcomingEvent = data.allContentfulTeDxKi.edges[0].node.upcomingEvent;
  const landingPageTitle = data.allContentfulTeDxKi.edges[0].node.landingPageTitle;
  const landingPageDescription = data.allContentfulTeDxKi.edges[0].node.landingPageDescription;
  const soldOut = data.allContentfulTeDxKi.edges[0].node.soldOut
  return (
    <div>
      <div className={styles.hero_image}>
        <Parallax bgImage={image1} strength={200}>
          <div style={{ height: 700 }} />
        </Parallax>
      </div>

      <Parallax bgImage={image2} strength={200}>
        <TEDxKIContainer>
          <div className={styles.tedxki_container}>
            <div className={styles.section}>
              <div className={styles.ticket_btn_container}>
                <TicketsBtn soldOut={soldOut} ticketSaleLink={upcomingEvent.ticketSaleLink} style={{ position: 'sticky' }}/>
              </div>              
              <div
                className={styles.event_title}
                dangerouslySetInnerHTML={{__html: landingPageTitle.childMarkdownRemark.html,}}>
              </div>
              <DateTimeLocation
                startTime={upcomingEvent.startTime}
                endTime={upcomingEvent.endTime}
                location={upcomingEvent.location}
              >
              </DateTimeLocation>
              <div class="anchorHoverUnderscore" dangerouslySetInnerHTML={{__html: landingPageDescription.childMarkdownRemark.html,}} />
              { upcomingEvent.speakers &&
                <div>
                  <h2>Speakers</h2>
                  <Container className="mt-3">
                    <Row className="justify-content-center">
                      {upcomingEvent.speakers.map(speaker => {
                        return (
                          <Col lg={4} xs={10} className="text-center mx-4 mt-2">
                            <Link to={speaker.linkedInProfileLink} target="_blank" rel="noreferrer">
                              <Img
                                className={styles.speaker_photo}
                                fluid={speaker.photo.fluid}
                                alt={speaker.name}
                              />
                            </Link>
                              <div class="mt-2">
                                <strong class="mb-1">{speaker.name}</strong>
                                <p>{speaker.jobTitle}</p>
                              </div>
                          </Col>
                        )
                      })}
                    </Row>
                  </Container>
                </div>
              }
            </div>
            <div className={styles.btn_container}>
              <ButtonWhite>
                <a href="/events/">See More</a>
              </ButtonWhite>
            </div>
          </div>
        </TEDxKIContainer>
      </Parallax>

      <TEDxKIContainer>
      <div className={styles.tedxki_container}>
          <div>
            <h1>
              About us
            </h1>
            <h4>
              TEDxKI is a platform for the propagation of Swedish ideas, creativity and innovation to the rest of the world.
            </h4>
            <p>
              <span>TEDxKI</span> is a volunteer-run, non-profit organisation founded back in 2020.
              We are an international team of 35 passionate people with diverse backgrounds in design,
              innovation, marketing, finance, NGOs, business, and health. Our mission is to find and present the most
              exciting and original ideas that transcend limitations, classifications, and boundaries to create a
              better society. Through out the year we strive to deliver high quality content and inspire you for new
              beginnings.
            </p>
          </div>
          <div>
            <h3 className="subtitle about-us__subtitle">What is TEDx?</h3>
            <p>
              In the spirit of  ideas change everything, <span>TED</span> has created a program called TEDx. <span>TEDx</span> is a program of local, self-organized events that bring people together to share a
              TED-like experience. Our event is called TEDxKI, where <span>x = independently organized TED event</span>.
            </p>
            <p>
              TED Talks video and live speakers will combine to spark deep discussion and connection in a small group.
              The TED Conference provides general guidance for the TEDx program, but individual TEDx events, including
              ours, are self-organized.
            </p>
          </div>
          <div>
            <ButtonWhite>
              <Link to="/about/">About</Link>
            </ButtonWhite>
          </div>
        </div>

        <hr/>

        <Container>
          <Row lg={2} xs={1}>
            <Col className="d-flex justify-content-center align-items-center" lg={{ order: 1 }} xs={{ order: 2 }}>
              <ButtonRed>
                <Link to="/get-involved/">Get Involved</Link>
              </ButtonRed>
            </Col>
            <Col lg={{ order: 2 }} xs={{order: 1}}>
              <div className="text-center">
                <h3>
                  Do you want to be a part of our TEDxKI team?
                </h3>
              </div>
            </Col>
          </Row>
        </Container>
      </TEDxKIContainer>
    </div>
  );
};

export const query = graphql`
  query {
    allContentfulTeDxKi {
      edges {
        node {
          soldOut
          landingPageTitle {
            childMarkdownRemark {
              html
            }
          }
          landingPageDescription {
            childMarkdownRemark {
              html
            }
          }
          upcomingEvent {
            speakers {
              photo {
                fluid(maxWidth: 317) {
                  ...GatsbyContentfulFluid
                }
              }
              jobTitle
              name
              bio {
              childMarkdownRemark {
                internal {
                    content
                  }
                }
              }
              linkedInProfileLink
            }
            performers {
              photo {
              fluid(maxWidth: 317) {
                  ...GatsbyContentfulFluid
                }
              }
              name
              bio {
                childMarkdownRemark {
                  internal {
                      content
                    }
                  }
              }
              title
            }
            startTime
            endTime
            location
            ticketSaleLink
          }
        }
      }
    }
  }
`
